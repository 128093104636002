/*
 * Copyright 2019 the original author or authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function registerAutosuggestControl() {
    BootstrapUiModule.Controls['AutoSuggest'] = {
        /**
         * Create a Typeahead autosuggest instance from a node with a configuration object.
         */
        create: function (node, configuration) {
            var typeahead = node.find('.js-typeahead');
            var selectedValue = node.find('.js-typeahead-value');
            var translateUrl = function (url) {
                return url.replace('{{controlName}}', encodeURIComponent(selectedValue.attr('name')));
            };
            var createBloodhoundEngine = function (configuration) {
                var base = {
                    datumTokenizer: Bloodhound.tokenizers.whitespace, queryTokenizer: Bloodhound.tokenizers.whitespace, identify: 'id', remote: {
                        wildcard: '{{query}}',
                    },
                };
                var options = $.extend(true, base, configuration);
                if (options.remote && options.remote.url) {
                    options.remote.url = translateUrl(options.remote.url);
                }
                if (options.prefetch && options.prefetch.url) {
                    options.prefetch.url = translateUrl(options.prefetch.url);
                }
                var engine = new Bloodhound(options);
                engine.initialize();
                return engine;
            };
            // Build datasets - bloodhound engine + typeahead config
            var datasets = configuration._datasets;
            delete configuration._datasets;
            var ttDataSets = [];
            var datasetsByName = {};
            var templatesByDataSet = {};
            // configure templates
            $(node).find('script[data-template]')
                .each(function (ix, value) {
                var asJqueryObject = $(value);
                var templateKey = asJqueryObject.data('template');
                if (templateKey && templateKey.includes('-')) {
                    var keys = templateKey.split('-');
                    var templateType = keys[0];
                    var datasetName = keys[1];
                    if (!templatesByDataSet[datasetName]) {
                        templatesByDataSet[datasetName] = {};
                    }
                    // merge with configuration object and then handlebars compile each value
                    templatesByDataSet[datasetName][templateType] = value.innerHTML;
                }
            });
            $.each(datasets, function (ix, value) {
                var engine = createBloodhoundEngine(value.bloodhound);
                delete value.bloodhound;
                var options = $.extend({ display: 'label' }, value);
                if (engine) {
                    options.source = engine.ttAdapter();
                }
                // merge view element templates with configuration templates
                if (templatesByDataSet[options.name]) {
                    options.templates = $.extend({}, templatesByDataSet[options.name], options.templates);
                }
                // handlebars compile each given template
                for (var template in options.templates) {
                    if (options.templates.hasOwnProperty(template)) {
                        options.templates[template] = Handlebars.compile(options.templates[template]);
                    }
                }
                datasetsByName[value.name] = engine;
                ttDataSets.push(options);
            });
            node.data('datasets', datasetsByName);
            // Initialize the typeahead
            typeahead.typeahead(configuration, ttDataSets);
            var selected;
            typeahead.on('typeahead:select', function (e, suggestion) {
                selected = suggestion;
                node.find('.js-typeahead-value').val(suggestion['id']);
            });
            typeahead.on('typeahead:change', function (e, val) {
                if (!selected || val !== selected['label']) {
                    typeahead.typeahead('val', '');
                    node.find('.js-typeahead-value').val('');
                }
            });
        },
    };
}
/**
 * Find and activate all auto-suggest instances with Typeahead and bloodhound.
 */
function autosuggestInitializer(node) {
    $('[data-bootstrapui-autosuggest]', node).each(function () {
        var configuration = $.extend({}, $(this).data('bootstrapui-autosuggest'));
        BootstrapUiModule.Controls.AutoSuggest.create($(this), configuration);
    });
}
export { registerAutosuggestControl };
export default autosuggestInitializer;
