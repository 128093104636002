/*
 * Copyright 2019 the original author or authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Find and activate all date time pickers as <a href="https://github.com/Eonasdan/bootstrap-datetimepicker">Eonasdan Bootstrap datepicker</a> elements.
 *
 * @author Steven Gentens
 * @since 2.2.0
 */
function initializeDateTimePickers(node) {
    $.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
        icons: {
            time: 'far fa-clock',
            date: 'far fa-calendar',
            up: 'fas fa-arrow-up',
            down: 'fas fa-arrow-down',
            previous: 'fas fa-chevron-left',
            next: 'fas fa-chevron-right',
            today: 'far fa-calendar-check',
            clear: 'fas fa-trash',
            close: 'fas fa-times',
        },
    });
    $('[data-bootstrapui-datetimepicker]', node).each(function () {
        var _this = this;
        var configuration = $.extend(true, {}, $(this).data('bootstrapui-datetimepicker'));
        var exportFormat = configuration.exportFormat;
        delete configuration.exportFormat;
        var formatAndSetDate = function (currentDate) {
            var formattedValue = currentDate ? moment(currentDate).format(exportFormat) : '';
            $('input[type=hidden]', $(_this)).attr('value', formattedValue);
        };
        $(this).datetimepicker(configuration);
        $(this).on('change.datetimepicker', function (e) { return formatAndSetDate(e.date); });
        $(this).find('input[type="text"]').on('blur focusout hide.datetimepicker', function () {
            var datetimepicker = $(_this).data('datetimepicker');
            datetimepicker.hide();
            formatAndSetDate(datetimepicker.date());
        });
        var initialDate = $(this).data('datetimepicker').date();
        formatAndSetDate(initialDate);
    });
}
export default initializeDateTimePickers;
